<template>
  <div>
    <a-modal :visible="visible" :title="$t(`打印订货订单`)" width="1200px" @cancel="handleCancle">
      <template #footer>
        <a-button @click="handleCancle">
          {{ $t(`取消`) }}
        </a-button>
        <a-button type="primary" @click="handleOk">
          {{ $t(`打印`) }}
        </a-button>
      </template>
      <div
        id="dispatch-info"
        class="shop-item mb40"
        v-for="(item, index) in dispatchInfoList"
        :key="index"
      >
        <div :id="'div1' + index" style="font-size: 12px">
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tbody>
              <tr>
                <td width="25%"></td>
                <td width="50%" style="text-align: center">
                  <span style="font-size: 20px; font-weight: 900">{{ $t(`订货订单`) }}</span>
                  <span style="font-size: 16px; margin-left: 10px">
                    ({{ $t('该批次') }}{{ getDate(item.arrivalDate) }})
                  </span>
                  <br />
                </td>
                <td width="25%">
                  PAGE:
                  <font tdata="PageNO" color="blue">##</font>
                  /
                  <font tdata="PageCount" color="blue">##</font>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="0" cellspacing="0" cellpadding="0" width="100%" style="margin-top: 10px">
            <tbody style="font-size: 14px">
              <tr>
                <td width="33%" style="font-size: 12px">
                  <span>{{ $t(`单据编号`) }}：{{ item.platformOrderSn }}</span>
                  <br />
                  <span>{{ $t(`出货仓`) }}：{{ item.warehouseName }}</span>
                </td>
                <td width="33%" style="font-size: 12px">
                  {{ $t(`单据类型`) }}：{{ item.type }}
                  <br />
                  <span>{{ $t(`收货门店`) }}：{{ item.shopName }}</span>
                </td>
                <td width="33%" style="font-size: 12px">
                  {{ $t(`单据日期`) }}：{{ item.platformOrderTime }}
                  <br />
                  {{ $t(`到货时间`) }}：{{ getDate(item.arrivalDate) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div style="font-size: 12px">
            {{ $t('收货信息') }}：{{ item.receiverName + '_' + item.receiverMobile }}
          </div>
          <div style="font-size: 12px">{{ $t('收货地址') }}：{{ item.receiverAddress }}</div>
          <div style="font-size: 12px">{{ $t('备注') }}：{{ item.memo }}</div>
        </div>
        <div :id="'div2' + index" style="font-size: 12px; margin-top: 10px">
          <table
            class="tableFa"
            cellspacing="0"
            cellpadding="1"
            width="100%"
            style="border-collapse: collapse"
            bordercolor="#333333"
          >
            <thead>
              <tr>
                <td width="5%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('序号') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('商品编码') }}</div>
                </td>
                <td width="15%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('商品名称') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('规格名称') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('订货数量') }}</div>
                </td>
                <td width="5%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('单位') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('订货总数') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('实发数') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('订货单价') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('金额') }}</div>
                </td>
                <td width="8%" style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <div align="center">{{ $t('备注') }}</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <template v-for="(ttem, tndex) in item.orderGoodsList">
                <tr :key="tndex">
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ tndex + 1 }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.skuCode }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.goodsName }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.attributeValue }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.quantity }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.unit }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ (ttem.orderQuantity || '') + ttem.orderUnit }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ (ttem.omsShippedQuantity || '') + ttem.orderUnit }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.orderQuantityPrice }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  >
                    {{ ttem.price }}
                  </td>
                  <td
                    align="center"
                    style="text-align: center; border: 1px solid gray; font-size: 12px"
                  ></td>
                </tr>
              </template>
              <tr>
                <td
                  style="
                    text-align: left;
                    border: 1px solid gray;
                    padding-left: 14px;
                    font-size: 12px;
                  "
                  colspan="3"
                >
                  {{ $t('合计') }}
                </td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <font tdata="Sum" color="blue">###</font>
                </td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <b>页脚</b>
                </td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <b>动态合计</b>
                </td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px">
                  <b>&nbsp;</b>
                </td>
                <td
                  tdata="pageNO"
                  format="#"
                  style="text-align: center; border: 1px solid gray; font-size: 12px"
                >
                  <b>
                    第
                    <font color="#0000FF">#</font>
                    页
                  </b>
                </td>
                <td
                  tdata="pageCount"
                  format="#"
                  style="text-align: center; border: 1px solid gray; font-size: 12px"
                >
                  <b>
                    总
                    <font color="#0000FF">##</font>
                    页
                  </b>
                </td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
                <td
                  tdata="subSum"
                  format="#,##0.00"
                  align="right"
                  style="text-align: center; border: 1px solid gray; font-size: 12px"
                >
                  <font color="#0000FF">###</font>
                </td>
                <td style="text-align: center; border: 1px solid gray; font-size: 12px"></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div :id="'div3' + index" style="margin-top: 20px; font-size: 12px">
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tbody style="font-size: 12px">
              <tr>
                <td width="25%">{{ $t(`客户签名`) }}：_________</td>
                <td width="25%">{{ $t(`仓管员`) }}：_________</td>
                <td width="25%">{{ $t(`送货人`) }}：_________</td>
                <td width="25%" style="position: relative">
                  {{ $t(`创建人`) }}：_________
                  <span class="name" style="position: absolute; left: 60px; bottom: 4px">
                    {{ item.createUserName }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getLodop } from '@/assets/LodopFuncs'
export default {
  props: {
    visible: Boolean,
    dispatchInfoList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.$nextTick(() => {
          this.previewMytable()
        })
      }
    },
  },
  methods: {
    handleOk() {
      this.previewMytable()
    },
    previewMytable() {
      var LODOP = getLodop()
      LODOP.PRINT_INIT('ShippingList')
      this.dispatchInfoList.forEach((item, index) => {
        if (index !== 0) {
          LODOP.NewPageA()
        }
        var strStyle =
          '<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse}</style>'
        LODOP.ADD_PRINT_TABLE(
          140,
          '5%',
          '90%',
          314,
          strStyle + document.getElementById(`div2${index}`).innerHTML
        )
        LODOP.SET_PRINT_STYLEA(0, 'Vorient', 3)
        LODOP.SET_PRINT_STYLEA(0, 'LinkNewPage', true)
        LODOP.ADD_PRINT_HTM(26, '5%', '90%', 149, document.getElementById(`div1${index}`).innerHTML)
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 1)
        LODOP.SET_PRINT_STYLEA(0, 'LinkedItem', -1)
        LODOP.ADD_PRINT_HTM(480, '5%', '90%', 54, document.getElementById(`div3${index}`).innerHTML)
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 1)
        LODOP.SET_PRINT_STYLEA(0, 'LinkedItem', -2)
      })
      LODOP.PREVIEW()
      this.$emit('getList', {})
      this.$emit('close', false)
    },
    handleCancle() {
      this.$emit('close', false)
    },
    getDate(dateString) {
      return dateString.split(' ')[0]
    },
  },
}
</script>
